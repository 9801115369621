// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import ujs from '@rails/ujs'

ujs.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('application.js loaded')

import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap'
import moment from 'moment'
import "chartkick/chart.js"
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.es';

require("stylesheets/application.scss")

// https://dev.to/brayvasq/integrate-andminlte-with-ruby-on-rails-6-od7
require('admin-lte');
global.datetimepicker = require("jquery-datetimepicker")
global.toastr = require("toastr");
global.intlTelInput = require('intl-tel-input');
global.select2 = require('select2')

window.jQuery = $;
window.$ = $;
window.moment = moment

import "@fortawesome/fontawesome-free/js/all";

let ready = function() {
    $('.datetimepicker').datetimepicker();
    jQuery.datetimepicker.setLocale('es');
    $('.datetimepicker_min').datetimepicker({
        minTime:'08:30',
        maxTime:'17:00',
        step : 30,
        minDate: 0,
        onSelect: function() {
            $(this).change();
    }});

    $('.select2').select2({});

    $('.datepicker').datepicker({
        format: 'dd-mm-yyyy',
        orientation: "bottom"
    });
}

window.onload = function() {
    ready();
}
$(document).ready( ready );

// execute ./bin/webpack for apply changes